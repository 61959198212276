const ActionType = {
  FOOTER_TAB: 'FOOTER_TAB', 
  FOOTER_HIDDEN: 'FOOTER_HIDDEN', 
  HEADER_POPOVER: 'HEADER_POPOVER', 
 
  USERS_PUT_USER: "USERS_PUT_USER", 
  USERS_PUT_ALL_USER: "USERS_PUT_ALL_USER", 
    
  POPUP_LOOSER_VISIBLE:"POPUP_LOOSER_VISIBLE", 
  
  POPUP_INFO_VISIBLE:"POPUP_INFO_VISIBLE",
  
  APP_MOBILE: "APP_MOBILE",
  APP_INFO: "APP_INFO",
  APP_MINING_INFO: "APP_MINING_INFO",
  APP_PROGRESS: "APP_PROGRESS",
  APP_SWITCH: "APP_SWITCH",
  APP_WAIT: "APP_WAIT", 
  APP_WAIT_COUNT: "APP_WAIT_COUNT", 
  APP_PAGES_SCREEN:"APP_PAGES_SCREEN",
  TIMER_DAYS_ENERGY:"TIMER_DAYS_ENERGY",
  TIMER_HOURS_ENERGY:"TIMER_HOURS_ENERGY",
  TIMER_MINUTES_ENERGY:"TIMER_MINUTES_ENERGY",
  TIMER_SECONDS_ENERGY:"TIMER_SECONDS_ENERGY", 
  
  TIMER_DAYS_DAILY:"TIMER_DAYS_DAILY",
  TIMER_HOURS_DAILY:"TIMER_HOURS_DAILY",
  TIMER_MINUTES_DAILY:"TIMER_MINUTES_DAILY",
  TIMER_SECONDS_DAILY:"TIMER_SECONDS_DAILY", 

  
  APP_LOADING:"APP_LOADING",    
   
}

export default ActionType;